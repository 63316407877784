import React, { useState, useEffect, useContext, useRef } from "react";
import ErrorModal from "../components/ErrorModal";
import QuickAddCard from "../components/QuickAddCard";
import {
  checkout,
  createOrder,
  enterAmount,
  getPaymentConfig,
  getPaymentDetails,
  getProfile,
  pushActivity,
  validateCoupon,
} from "../services/apiServices";
import SuccessModal from "../components/SuccessModal";
import Header from "../components/Header";
import { AnalyticsContext } from "../context/Analytics";
import { AuthContext } from "../context/Auth";
import { navigate } from "gatsby";
import CheckoutAddressForm from "../components/CheckoutAddressForm";
import PaymentDetailModal from "../components/PaymentDetailModal";
import crypto from "crypto";

const cardModes = ["card", "dc", "cc", "ccc", "ppc"];
const quickAddDataForMeesho = [
  {
    "isGold": false,
    "amount": 50,
    "finalAmount": 100,
    "code": "BM50ZQX1A2B",
    "tag": "",
    "text": "Most Avail"
  },
  {
    "isGold": false,
    "amount": 100,
    "finalAmount": 220,
    "code": "BM100YTR3C4D",
    "tag": "",
    "text": "Average payment"
  },
  {
    "isGold": false,
    "amount": 500,
    "finalAmount": 1500,
    "code": "MEESHOBODHI500LMN5E6F",
    "tag": "",
    "text": "Average payment"
  },
  {
    "isGold": false,
    "amount": 1000,
    "finalAmount": 3000,
    "code": "MEESHOBODHI1000PQR7G8H",
    "tag": "",
    "text": "Average payment"
  },
  {
    "isGold": false,
    "amount": 2500,
    "finalAmount": 8000,
    "code": "BM2500ABC9I0J",
    "tag": "",
    "text": "Average payment"
  },
  {
    "isGold": false,
    "amount": 5000,
    "finalAmount": 20000,
    "code": "BM5000DEF1K2L",
    "tag": "",
    "text": "Average payment"
  }
]

function Payment({ location }) {

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script);
    })
  }

  const bottomRef = useRef(null);
  const [responseId, setResponseId] = React.useState("");
  const [orderIdR, setOrderIdR] = React.useState("");
  const paymentStatus = new URLSearchParams(location.search).get("status");
  const [status, setstatus] = useState("");
  const orderId = new URLSearchParams(location.search).get("order_id");
  const paymentIntent = new URLSearchParams(location.search).get(
    "payment_intent"
  );
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState({});
  const [meeshoData, setMeeshoData] = useState({});
  const [quickAddData, setQuickAddData] = useState(quickAddDataForMeesho);
  const [amount, setamount] = useState("");
  const [code, setcode] = useState("");
  const [codeApplied, setcodeApplied] = useState("");
  const [validateText, setvalidateText] = useState("");
  const [error, seterror] = useState(false);
  const [eMessaage, seteMessaage] = useState("");
  const [balance, setbalance] = useState(0);
  const { gtmCustomEvent, gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmCustomEvent: () => { },
    gtmPageViewEvent: () => { },
  };
  const [host, sethost] = useState("");
  const { user } = useContext(AuthContext) || { user: {} };
  const [billingShow, setbillingShow] = useState(false);
  const [method, setmethod] = useState(-1);
  const [modes, setmodes] = useState([]);
  const [showOptions, setshowOptions] = useState(true);
  const [showDetail, setshowDetail] = useState(false);
  const [paymentDetail, setpaymentDetail] = useState({});
  const [paymentSessionId, setpaymentSessionId] = useState("");
  const [payUData, setPayUData] = useState({});
  const [usdToInrFlow, setUsdToInrFlow] = useState(false);

  useEffect(async () => {
    sethost(window.location.host);
    gtmPageViewEvent(window.location.pathname, window.location.host, user.meesho_userId);

    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (user?._id) {
      const activityData = {
        userId: user?._id,             // User ID
        eventType: "click",                 // Event Type (e.g., click)
        eventDetails: "payment-page",       // Details of the event (e.g., payment-page)
        pageUrl: "/payment",               // The page URL where the event occurred
        userAgent: navigator.userAgent,     // Browser/Device info (use navigator.userAgent in the browser)
      };
      await pushActivity(activityData)
    }


    const fn = async () => {
      let hostName = window.location.host;
      const res = await enterAmount(hostName);

      if (res.statusCode === 200) {
        setdata({ ...res.payLoad });
        setloading(false);
        bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }

      let platform = "WEB";
      if (hostName == "astroguru.bodhiness.com") {
        platform = "ASTROGURU";
      } else if (hostName == "mobafl.bodhiness.com") {
        platform = "MOBAFL";
      } else if (hostName == "webj.bodhiness.com") {
        platform = "WEBJ";
      } else if (hostName == "lokal.bodhiness.com") {
        platform = "LOKAL";
      } else if (hostName == "namah.bodhiness.com") {
        platform = "NAMAH";
      } else if (hostName == process.env.GATSBY_JIO_BASE) {
        platform = "JIO";
      } else if (hostName == process.env.GATSBY_MEESHO_BASE) {
        platform = "MEESHO";
      }
      let rr = await getPaymentConfig(platform);
      if (rr.statusCode == 200) {
        let modes = rr.payLoad.processedConfigs.modes;
        let keys = Object.keys(modes);
        let vals = Object.values(modes);
        let uniqueVals = vals.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        if (uniqueVals.length == 1) {
          setmethod(uniqueVals[0]);
          setshowOptions(false);
        } else {
          let md = [];

          for (let k = 0; k < cardModes.length; k++) {
            if (keys.indexOf(cardModes[k]) > -1) {
              md.push({
                name: "Credit / Debit Card / Others",
                method: modes[keys[keys.indexOf(cardModes[k])]],
              });
              break;
            }
          }
          if (keys.includes("paypal")) {
            md.push({ name: "Paypal", method: modes["paypal"] });
          }
          setmodes([...md, { name: "Pay Using Indian Cards (in INR)", method: 3, id: "usdToInr" }]);
          if (md.length == 1) {
            setmethod(md[0].method);
            setshowOptions(false);
          }
        }
        // setmethod(rr.payLoad.processedConfigs.serviceProvider);
      }
    };
    fn();
  }, []);

  useEffect(async() => {
    // const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")
    // const instance = new window.Razorpay({ key_id: 'rzp_test_wXkPwKBJ9oOHAh', key_secret: 'kWbYk5McYm0Gj3Dq9YJSr98r' })

    const fn = async () => {
      setloading(true);
      let d;
      if (paymentIntent) {
        d = {
          gateway: 2,
          order_id: paymentIntent,
        };
      } else {
        if (responseId) {

          var hmac = crypto.createHmac("SHA256", process.env.GATSBY_RAZOR_PAY_KEY_SECRET);
          hmac.update(orderId + "|" + responseId);
          let generatedSignature = hmac.digest('hex');
          d = {
            gateway: 0,
            order_id: orderIdR,
            signature : generatedSignature,
            payment_id : responseId
          };
        } else {
          d = {
            gateway: 1,
            order_id: orderId,
          };
        }
      }
      const res = await checkout(d);
      if (res.statusCode === 200) {
        let v = parseInt(localStorage.getItem("pa"));
        let uc = localStorage.getItem("uc");
        gtmCustomEvent(
          "purchase",
          { value: v, currency: uc },
          window.location.host
        );
        setstatus("success");
      }
    };
    if (orderId || paymentIntent || responseId) {
      fn();
    }
  }, [orderId, paymentIntent, responseId]);

  useEffect(() => {
    const fn = async () => {
      const res = await getProfile();
      if (res.statusCode === 200) {
        setbalance(res?.payLoad?.cashBalance);
      }
    };
    fn();
  }, []);

  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus == "paymentSuccess") {
        setstatus("success");
      } else if (paymentStatus == "paymentFailed") {
        seteMessaage("Payment failed.");
        seterror(true);
      }
      setloading(false);
      setcode("");
    }
  }, []);

  const handleCodeApply = async () => {
    let platform = "WEB";
    if (host == "astroguru.bodhiness.com") {
      platform = "ASTROGURU";
    } else if (host == "mobafl.bodhiness.com") {
      platform = "MOBAFL";
    } else if (host == "webj.bodhiness.com") {
      platform = "WEBJ";
    } else if (host == "lokal.bodhiness.com") {
      platform = "LOKAL";
    } else if (host == "namah.bodhiness.com") {
      platform = "NAMAH";
    } else if (host == process.env.GATSBY_JIO_BASE) {
      platform = "JIO";
    } else if (host == process.env.GATSBY_MEESHO_BASE) {
      platform = "MEESHO";
    }
    let res = await validateCoupon(code, parseInt(amount), platform);
    if (res.statusCode === 200) {
      setvalidateText(res.payLoad.message);
      gtmCustomEvent("promo_code_applied");
    } else {
      seteMessaage(res?.message);
      seterror(true);
      setcode("");
    }
  };

  const handleProceedToPayCheck = async () => {
    if (method == -1) {
      setbillingShow(true);
    } else if (method == 2) {
      setbillingShow(true);
    } else {
      handleProceedToPay();
    }
  };

  const handleGetPaymentDetail = async () => {
    try {

      let d = {
        amount: amount,
        code: code
      };
      let res = await getPaymentDetails(d);
      if (res.statusCode == 200) {
        setpaymentDetail({ ...res.payLoad.paymentDetails });
        setshowDetail(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleProceedToPay = async (name, address) => {
    gtmCustomEvent("proceed_to_pay");
    setloading(true);
    let coupon;
    if (validateText) {
      coupon = code;
    }
    let d = {
      amount,
      coupon,
      platform: "WEB",
    };
    if (name && address) {
      d.name = name;
      d.address = address;
    }
    // console.log(host, "host");
    if (host == "astroguru.bodhiness.com") {
      d.platform = "ASTROGURU";
    } else if (host == "mobafl.bodhiness.com") {
      d.platform = "MOBAFL";
    } else if (host == "webj.bodhiness.com") {
      d.platform = "WEBJ";
    } else if (host == "lokal.bodhiness.com") {
      d.platform = "LOKAL";
    } else if (host == "namah.bodhiness.com") {
      d.platform = "NAMAH";
    } else if (host == process.env.GATSBY_JIO_BASE) {
      d.platform = "JIO";
    } else if (host == process.env.GATSBY_MEESHO_BASE) {
      d.platform = "MEESHO";
    } else {
      d.webBaseUrl = "https://www.bodhi.app/";
    }
    localStorage.setItem("pa", amount);
    let mt = "cc";
    if (method == 2) {
      mt = "card";
    }
    if (method == 3) {
      mt = "debit_or_credit";
      d.isUsdToInrFlow = usdToInrFlow;
    }

    d.method = mt;

    localStorage.setItem("uc", user.currencyType.toUpperCase());
    let res = await createOrder(d);
     if (res.statusCode === 200) {
      if (method == 2) {
        navigate("/checkout", {
          state: { client_secret: res?.payLoad?.client_secret },
        });
      } else {
        const psId = res?.payLoad?.payment_session_id;
        if (psId) {
          setpaymentSessionId(psId);
          setTimeout(() => setloading(false), 100);
        } else if (res?.payLoad?.gateway == 3) {
          setPayUData(res?.payLoad);
          setTimeout(function () {
            document.getElementById("payuForm").submit()
          }, 400);
        } else {
          setOrderIdR(res?.payLoad.order_id)
          handleRazorpayScreen(res?.payLoad)
          // setloading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (paymentSessionId && typeof (document) !== "undefined") {
      const { cashfreeProd } = require('cashfree-pg-sdk-javascript')
      const cashfree = new cashfreeProd.Cashfree(paymentSessionId);
      cashfree.redirect();
    }

  }, [paymentSessionId]);

  const handleRazorpayScreen = async (payload) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

    if (!res) {
      alert("Some error at razorpay screen loading")
      return;
    }

    // const options = {
    //   key: process.env.GATSBY_RAZOR_PAY_KEY_ID,
    //   amount: Math.round(payload.amount * 100),
    //   currency: 'INR',
    //   name: payload.merchantName,
    //   description: "Payment for Bodhi",
    //   image: payload.image,
    //   order_id: payload.order_id,
    //   handler: function (response) {
    //     setResponseId(response.razorpay_payment_id)
    //   },
    //   prefill: {
    //     name: "",
    //     email: "",
    //     contact: user.phoneNumber
    //   },
    //   theme: {
    //     color: "#F4C430"
    //   }
    // }
    const options = {
      key: process.env.GATSBY_RAZOR_PAY_KEY_ID, // Razorpay Key ID from environment variables
      amount: Math.round(payload.amount * 100), // Amount in paise (for INR)
      currency: 'INR',
      name: payload.merchantName, // Merchant name
      description: "Payment for Bodhi", // Description for payment
      image: payload.image, // Merchant logo or image
      order_id: payload.order_id, // Razorpay order ID
      handler: function (response) {
        setResponseId(response.razorpay_payment_id); // Handle the response and payment ID
      },
      prefill: {
        name: "", // Prefill customer name
        email: "", // Prefill customer email
        contact: user.phoneNumber // Prefill customer phone number
      },
      theme: {
        color: "#FFA500" // Theme color for Razorpay checkout
      },
      method: "upi", // Set the method to UPI
      intent_flow: "none", // Enable intent flow for a smoother UPI experience in web view
      target: "_self",
      // Enabling retry for failure scenarios (Optional)
      retry: {
        enabled: true,
        max_count: 3
      }
    };
  
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }
  return (
    <>
      {billingShow && (
        <CheckoutAddressForm
          handleSubmit={handleProceedToPay}
          setbillingShow={setbillingShow}
          method={method}
          setmethod={setmethod}
          modes={modes}
          showOptions={showOptions}
          setUsdToInrFlow={setUsdToInrFlow}
          loading={loading}
        />
      )}
      <div className="flex flex-col h-screen worksans">
        {status === "success" && (
          <SuccessModal data={{ message: "Payment Successful", path: "/consultAstro" }} />
        )}
        {error && <ErrorModal data={{ message: eMessaage, seterror }} />}
        {showDetail && <PaymentDetailModal data={{ amount, setshowDetail, paymentDetail, handleProceedToPayCheck, loading }} />}
        <Header />
        <form action={process.env.GATSBY_PAYU_CONFIRMATION_URL} method='post' id="payuForm">
          <input type="hidden" name="key" value={payUData?.key} />
          <input type="hidden" name="txnid" value={payUData?.transactionId} />
          <input type="hidden" name="productinfo" value={payUData?.productInfo} />
          <input type="hidden" name="amount" value={payUData?.amount} />
          <input type="hidden" name="email" value={payUData?.email} />
          <input type="hidden" name="firstname" value={payUData?.firstName} />
          <input type="hidden" name="surl" value={payUData?.surl} />
          <input type="hidden" name="furl" value={payUData?.furl} />
          <input type="hidden" name="phone" value={user.phoneNumber} />
          <input type="hidden" name="hash" value={payUData?.hash} />
        </form>
        <main className="flex-1 overflow-y-auto p-1 flex items-center justify-center">
          <div className="w-full lg:w-2/3 rounded-2xl shadow-2xl overflow-hidden">
            <h1 className="text-lg lg:text-2xl p-4">
              {"Current Balance: " +
                (user?.currencyType === "usd" ? "$" : "₹") +
                balance}
            </h1>
            <div className="w-full h-auto flex justify-center items-center bg-gradient-to-r from-[#C1E1FF] to-[#FFE7C2] p-2">
              <div className="h-10 w-10  bg-white flex justify-center items-center rounded-full ml-2">
                👍
              </div>
              {host == "astroguru.bodhiness.com" &&
                user?.currencyType === "inr" ? (
                <div className="ml-4 p-1 text-base lg:text-lg">
                  <h2>{"First consult for Rs. 25 only - Use code 1FOR4"}</h2>
                </div>
              ) : (
                <>
                  {
                    host != "meesho.bodhiness.com" ? (
                      <div className="ml-4 p-1 text-base lg:text-lg">
                        <h2>{data?.offerText}</h2>
                      </div>
                    ) : ""
                  }
                </>
              )}
            </div>
            <h2 className="text-gray-500 text-xs lg:text-base ml-4 mt-2">
              {data.minimumBalanceText}
            </h2>


            {/* {
              host != 'staging-meesho.bodhiness.com' && host != 'meesho.bodhiness.com' && host != 'localhost:8000' ?
                (
                  <><h3 className="ml-4 mt-2 lg:text-lg font-semibold">
                    Add Money to Bodhi Wallet
                  </h3>
                    <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                      <div className="flex flex-nowrap lg:ml-10 md:ml-20 ml-2">
                        {data?.quickAddValues?.map((v, i) => {
                          return (
                            <button
                              key={i}
                              onClick={() => {
                                setamount(v?.amount);
                                setcode(v?.code);
                                setvalidateText(v.text);
                              }}
                            >
                              <QuickAddCard data={v} />
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )
                :
                <>
                  <h3 className="ml-4 mt-2 lg:text-lg font-semibold">
                    Add Money to Bodhi Wallet
                  </h3>
                  <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                    <div className="flex flex-nowrap lg:ml-10 md:ml-20 ml-2">
                      {quickAddData?.map((v, i) => {
                        return (
                          <button
                            key={i}
                            onClick={() => {
                              setamount(v?.amount);
                              setcode(v?.code);
                              setvalidateText(v.text);
                            }}
                          >
                            <QuickAddCard data={v} />
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </>
            } */}

            <>
              <h3 className="ml-4 mt-2 lg:text-lg font-semibold">
                Add Money to Bodhi Wallet
              </h3>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap lg:ml-10 md:ml-20 ml-2">
                  {user.platform != "meesho" ? data?.quickAddValues?.map((v, i) => {
                    return (
                      <button
                        key={i}
                        onClick={() => {
                          setamount(v?.amount);
                          setcode(v?.code);
                          setvalidateText(v.text);
                        }}
                      >
                        <QuickAddCard data={v} />
                      </button>
                    );
                  }) : data?.quickAddValues?.map((v, i) => {
                    return (
                      <button
                        key={i}
                        onClick={() => {
                          setamount(v?.amount);
                          setcode(v?.code);
                          setvalidateText(v.text);
                        }}
                      >
                        {/* {console.log(v,"vvv")} */}
                        {
                          v.isMeesho ? <QuickAddCard data={v} /> : ""
                        }

                      </button>
                    );
                  })}
                </div>
              </div>
            </>

            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col items-center mt-4 w-full lg:w-1/2 lg:mt-16">
                <input
                  className="appearance-none border rounded w-11/12 py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                  id="amount"
                  type="number"
                  placeholder="Add Amount"
                  value={amount}
                  onChange={(e) => {
                    setamount(e.target.value);
                    setvalidateText("");
                    setcode("");
                  }}
                ></input>
              </div>

              {
                host != 'staging-meesho.bodhiness.com' && host != 'meesho.bodhiness.com' && host != 'localhost:8000' ? <div className="w-full lg:w-1/2">
                  <h2 className="ml-4 mt-6 font-semibold">Got a Promocode!</h2>
                  <div className="flex flex-col items-center mt-1">
                    <div className="flex items-center border py-2 w-11/12 mt-2">
                      <input
                        className="w-full uppercase appearance-none border-none text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        placeholder="Add promocode here"
                        aria-label="Promocode"
                        value={code}
                        onChange={(e) => {
                          setcode(e.target.value);
                          setvalidateText("");
                        }}
                      />
                      {code && !codeApplied && !validateText && (
                        <button
                          className="border-transparent text-sm py-1 px-2 rounded text-yellow-500"
                          type="button"
                          onClick={() => {
                            handleCodeApply();
                          }}
                        >
                          Apply
                        </button>
                      )}
                    </div>
                  </div>
                  <h2 className="text-[#FA942F] ml-4 text-xs mt-1">
                    {validateText}
                  </h2>
                </div> : ''

              }

            </div>
            <div ref={bottomRef} className="h-16 bg-white w-full shadow-2xl flex justify-between px-4 py-2 mt-8">
              {amount ? (
                <div className="flex flex-col">
                  <div className="flex gap-2 items-center">

                    <h2 className="text-lg">
                      {(user?.currencyType === "usd" ? "$ " : "₹ ") + ((data.isTaxActive && data.taxPercentage > 0) ? (parseInt(amount) + parseInt(amount * data.taxPercentage / 100)) : amount)}
                    </h2>
                    {data.isTaxActive && data.taxPercentage > 0 && <h6 className="text-[10px] text-gray-500">{`(with ${data.taxPercentage}% GST)`}</h6>}
                  </div>
                  {user?.currencyType != "usd" && <a className="text-[#72a12d] text-[12px] px-4 py-0.5 bg-[#87BD36]/10 cursor-pointer rounded-full hover:outline hover:outline-[#87BD36]/50 hover:bg-[#87BD36]/20" onClick={() => { handleGetPaymentDetail(); }}>VIEW PRICE DETAILS</a>}
                </div>
              ) : (
                <div></div>
              )}
              {!loading ? (
                amount > 0 ? (
                  <button
                    type="button"
                    className="bg-[#87BD36] py-2 px-4 rounded-lg shadow text-white hover:bg-[#72a12d]"
                    onClick={() => {
                      handleProceedToPayCheck();
                    }}
                  >
                    Proceed to Pay
                  </button>
                ) : (
                  <button
                    type="button"
                    className="bg-gray-100 py-2 px-4 rounded-lg shadow text-gray-300 "
                  >
                    Proceed to Pay
                  </button>
                )
              ) : (
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-8 py-2 font-semibold text-sm shadow rounded-md text-white bg-[#87BD36] cursor-not-allowed"
                  disabled=""
                >
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Payment;